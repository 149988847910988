//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  components: {
    Datepicker: () => {
      if (process.client) {
        return import('vuejs-datepicker');
      }
    },
  },
  props: {
    externalUpdatedValue: {
      required: false,
      type: String,
    },
    rightAlignCalendar: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      input: null, // Initialize input to null
    };
  },
  watch: {
    externalUpdatedValue(value) {
      if (value && value !== this.input) {
        this.input = new Date(value);
      }
    },
  },
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  computed: {
    ...mapGetters({
      getDeliveryInfo: 'checkout/getDeliveryInfo',
    }),
    format() {
      if (this.data.dateFormat === 'ymd') {
        return 'yyyy/MM/dd';
      } else if (this.data.dateFormat === 'dmy') {
        return 'dd/MM/yyyy';
      } else if (this.data.dateFormat === 'ymd') {
        return 'yyyy/MM/dd';
      } else if (this.data.dateFormat === 'dmy_dash') {
        return 'dd-MM-yyyy';
      } else if (this.data.dateFormat === 'ymd_dash') {
        return 'yyyy-MM-dd';
      } else if (this.data.dateFormat === 'dmy_dot') {
        return 'dd.MM.yyyy';
      } else if (this.data.dateFormat === 'ymd_dot') {
        return 'yyyy.MM.dd';
      } else {
        return 'dd/MM/yyyy';
      }
    },
    rules() {
      let allRules = {};
      if (this.data.isRequired) {
        allRules = {
          ...{
            required: {
              allowFalse: false,
            },
          },
        };
      }

      if (this.data.disabledDates) {
        allRules = {
          ...allRules,
          disabledDateValidation: [
            {
              disabledDates: this.data.disabledDates,
            },
          ],
        };
      }

      return allRules;
    },
  },
  methods: {
    detailsUpdated() {
      this.$nextTick(async () => {
        const result = await this.$refs.vp.validate();
        this.$emit('detailsUpdated', {
          fieldName: this.data.fieldName,
          value: this.input,
          errors: result.errors,
        });
      });
    },
  },
};
