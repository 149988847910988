var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShow),expression:"shouldShow"}],staticClass:"ui-form-field",class:_vm.customClass},[_c('div',{staticClass:"ui-form-field__label"},[(_vm.data.label)?_c('div',{staticClass:"text",domProps:{"textContent":_vm._s(_vm.data.label)}}):_vm._e(),_vm._v(" "),(_vm.data.isRequired)?_c('div',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),_vm._v(" "),_c('validation-provider',{attrs:{"vid":'validate_' + _vm.getFieldId,"rules":_vm.rules,"customMessages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"ui-form-field__input-wrapper",class:errors.length > 0 ? 'has-error' : ''},[(_vm.defSelect)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"def-select",class:{
          'has-error': errors.length > 0,
        },attrs:{"name":_vm.data.label,"placeholder":_vm.data.placeholder,"required":_vm.data.isRequired},on:{"input":_vm.detailsUpdated,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.input=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_vm._l((_vm.data.choices),function(choice){return [_c('option',{key:choice.value,attrs:{"select":_vm.data.defaultValue === choice.value,"disabled":choice.disabled},domProps:{"value":choice.value}},[_vm._v("\n            "+_vm._s(choice.text)+"\n          ")])]})],2):_c('v-select',{class:{
          'has-error': errors.length > 0,
        },attrs:{"name":_vm.data.label,"required":_vm.data.isRequired,"placeholder":_vm.data.placeholder,"multiple":_vm.data.type === 'multiselect',"clearable":false,"disabled":_vm.isSelectDisabled,"options":_vm.filteredOptions},on:{"input":_vm.detailsUpdated},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),_vm._v(" "),(_vm.data.description)?_c('div',{staticClass:"ui-form-field__instruction",class:{
          top: _vm.data.descriptionPlacement === 'top',
          bottom: _vm.data.descriptionPlacement === 'bottom',
        }},[_c('span',{domProps:{"textContent":_vm._s(_vm.data.description)}})]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"ui-form-field__error"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }