import { mapGetters } from 'vuex';

const CalculateConditionalLogicMixins = {
  computed: {
    ...mapGetters({
      getFormFieldsValue: 'gforms/getFormFieldsValue',
    }),
    shouldShow() {
      if (this.data.conditionalLogic) {
        return this.calculateShouldShow(this.data);
      }
      return true;
    }
  },
  methods: {
    calculateShouldShow(field) {
      let result = [];

      // Step 1: evaulate each rule's truth value
      field.conditionalLogic.rules.forEach(rule => {
        let valueObj = this.getFormFieldsValue(this.id, rule.fieldId);
        if (this.data.id === 8) console.log('operator ', rule.operator);
        if (valueObj === null || !valueObj) {
          result.push(false);
        } else {
          let value = valueObj.value;
          if (valueObj.type === 'select') {
            value = valueObj.value.code;
            result.push(this.compare(rule.operator, value, rule.value));
          } else if ( valueObj.type === 'multiselect') {
            let tester = false;
            valueObj.value.forEach(value => {
              if (this.compare(rule.operator, value.code, rule.value)) {
                tester = true;
                return;
              }
            });
            result.push(tester);
          } else {
            let value = valueObj.value;
            result.push(this.compare(rule.operator, value, rule.value));
          }
        }
      });

      // Step 2: concat each truth value according to logic type
      let truth = result[0];
      result.forEach(val => {
        truth = this.compare(field.conditionalLogic.logicType, truth, val);
      });

      if (field.conditionalLogic.actionType === 'show') {
        if ( truth ) {
          return true;
        } else {
          return false;
        }
      } else if ( field.conditionalLogic.actionType === 'hide' ) {
        if ( truth ) {
          return false;
        } else {
          return true;
        }
      }
    },
    compare(operator, val1, val2) {
      if (operator === 'is') {
        return val1 === val2;
      } else if ( operator === 'isnot') {
        return val1 !== val2;
      } else if ( operator === '>') {
        return val1 > val2;
      } else if ( operator === '<') {
        return val1 < val2;
      } else if ( operator === 'contains') {
        return val1.includes(val2);
      } else if ( operator === 'starts_with') {
        return val1.startsWith(val2, 0);
      } else if ( operator === 'ends_with') {
        return val1.endsWith(val2);
      } else if (operator === 'all') {
        return val1 && val2;
      } else if (operator === 'any') {
        return val1 || val2;
      }
    },
  }
};

export default CalculateConditionalLogicMixins;
