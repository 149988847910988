import { ValidationProvider } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';

/* eslint-disable */
const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'ico', 'svg', 'webp'];
const documentTypes = ['doc', 'docx', 'xls', 'xlsx'];
const applicationTypes = ['pdf', 'xml', 'json', 'ld+json', 'zip'];
const videoTypes = ['m4v', 'mp4', 'mov', 'wmv', 'avi'];
const audioTypes = ['mp3', 'm3v', 'wav'];
/* eslint-enable */

const FormMixins = {
  components: {
    ValidationProvider,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    formErrors: {
      required: false,
      type: Object,
    },
    clear: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      input: '',
    };
  },
  watch: {
    clear(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.input = '';
      }
    },
    input(newVal, oldVal) {
      if (newVal !== oldVal) {
        let value = newVal;

        if (this.data.type === 'date') {
          value = this.$dayjs(newVal).format('DD/MM/YYYY');
        }

        let data = {
          formID: this.data.formId,
          fieldID: this.data.id,
          type: this.data.type,
          value: value,
        };

        if (newVal === '' || !newVal || !this.shouldShow) {
          this.removeFormFieldValue(data);
        } else {
          this.setFormFieldValue(data);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getCurrentFieldValue: 'gforms/getCurrentFieldValue',
    }),
    currentFieldValue() {
      return this.getCurrentFieldValue(this.id, this.data.id);
    },
    currentErrors() {
      return this.formErrors['validate_' + this.fieldId];
    },
    fieldId() {
      return 'ui_form_field_id__' + String(this.data.id).replace('.', '_');
    },
    isClean() {
      return this.input === '';
    },
    charLimit() {
      if (this.data.hideCharLimit) {
        return false;
      }

      return this.data.maxLength && this.data.maxLength > 0
        ? this.$t('form.validations.max_length', { len: this.data.maxLength })
        : false;
    },
    customMessages() {
      return {
        required: this.$t('form.validations.required', {
          name: this.data.label,
        }),
        email: this.$t('form.validations.email'),
        max: this.$t('form.validations.max', { count: this.data.maxLength }),
        min: this.$t('form.validations.min', { count: this.data.minLength }),
        mimes: this.$t('form.validations.mimes'),
        size: this.$t('form.validations.size'),
      };
    },
    customClass() {
      return `${this.data.cssClass} ${
        this.data.isRequired ? '' : this.data.visibility
      } ${this.fieldId}`;
    },
    rules() {
      let rules = '';

      if (this.shouldShow) {
        if (this.data.isRequired) {
          rules += 'required';
        }

        if (this.data.type === 'email') {
          rules += '|email';
        }

        if (this.data.type === 'postcode') {
          rules += '|integer';
        }

        if (this.data.minLength && this.data.minLength > 0) {
          rules += '|min:' + this.data.minLength;
        }

        if (this.data.maxLength && this.data.maxLength > 0) {
          rules += '|max:' + this.data.maxLength;
        }

        if (this.data.type === 'fileupload') {
          if (this.data.allowedExtensions) {
            rules += this.getStandardMimes(
              this.data.allowedExtensions.split(',')
            );
          }
          if (parseInt(this.data.maxFileSize) * 100 !== 0) {
            rules += '|size:' + parseInt(this.data.maxFileSize) * 1024;
          }
        }

        if (this.data.type === 'phone') {
          if (/mobile/i.test(this.data.label)) {
            rules += '|mobilePhone' + '|min:10';
          } else {
            rules += '|phone';
          }
        }

        if (this.data.type === 'mobilePhone') {
          rules += '|mobilePhone';
        }

        // Remove leading pipe symbol
        if (rules.charAt(0) === '|') {
          rules = rules.slice(1);
        }
      }

      return rules;
    },
  },
  methods: {
    ...mapActions({
      setFormFieldValue: 'gforms/setFormFieldValue',
      removeFormFieldValue: 'gforms/removeFormFieldValue',
    }),
    getDefaultValues() {
      if (this.data.defaultValue) {
        this.input = this.data.defaultValue;
      }
    },
    getStandardMimes(mimes) {
      let rules = '';
      rules += '|mimes:';
      mimes.forEach(function(extension) {
        if (documentTypes.includes(extension)) {
          rules += 'document/' + extension;
          rules += ',';
        } else if (applicationTypes.includes(extension)) {
          rules += 'application/' + extension;
          rules += ',';
        } else if (imageTypes.includes(extension)) {
          rules += 'image/' + extension;
          rules += ',';
        } else if (audioTypes.includes(extension)) {
          rules += 'audio/' + extension;
          rules += ',';
        } else if (videoTypes.includes(extension)) {
          rules += 'video/' + extension;
          rules += ',';
        }
      });
      rules = rules.substring(0, rules.length - 1);
      return rules;
    },
  },
  mounted() {
    this.getDefaultValues();
    if (this.currentFieldValue) {
      this.input = this.currentFieldValue;
    }
  },
};

export default FormMixins;
