//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  components: {
    VSelect: () => import('vue-select'),
  },
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  props: {
    externalUpdatedValue: {
      type: String,
      required: false,
    },
    defSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    input(newVal, oldVal) {
      if (newVal !== oldVal) {
        let data = {
          formID: this.data.formId,
          fieldID: this.data.id,
          type: this.data.type,
        };
        if (
          (newVal && typeof newVal === 'object' && newVal.length === 0) || // Remove conditions
          newVal === '' ||
          !newVal ||
          !this.shouldShow
        ) {
          this.removeFormFieldValue(data);
        } else {
          // Add conditions
          let values = [];
          if (this.data.type === 'multiselect' && typeof newVal === 'object') {
            newVal.forEach(function(val) {
              values.push(val);
            });
          } else {
            values = newVal;
          }
          data.value = values;
          this.setFormFieldValue(data);
        }
      }
    },
    externalUpdatedValue(value) {
      this.input = value;
    },
  },
  methods: {
    detailsUpdated(errors) {
      setTimeout(() => {
        this.$emit('detailsUpdated', {
          fieldName: this.data.fieldName,
          value: this.input,
          errors: errors,
        });
      }, 0);
    },
  },
  computed: {
    filteredOptions() {
      let options = [];
      let self = this;
      this.data.choices.forEach(function(choice) {
        let value = {
          label: choice.text,
          code: choice.value,
        };
        options.push(value);
        if (choice.isSelected) {
          self.input = value;
        }
      });
      return options;
    },
    isSelectDisabled() {
      return this.data?.disabled ?? false;
    },
    getFieldId() {
      return this.fieldId ? this.fieldId : this.data.fieldId;
    },
  },
  mounted() {
    this.getDefaultValues();
    if (this.currentFieldValue) {
      this.input = this.currentFieldValue;
    }
    if (this.data.defaultValue !== undefined) {
      this.input = this.data.defaultValue;
    }
  },
};
